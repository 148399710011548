.sort-buttons {
  font-size: 15px;
  color: $white;
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  margin-bottom: 30px;

  a {
    display: inline-block;
    padding: 5px;
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}
