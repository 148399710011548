.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    padding: 0 20px;
    max-width: 460px;
  }

  .logo {
    margin-bottom: 108px;
    @include mobile {
      margin-bottom: 57px;
    }
  }

  .button--login {
    margin-top: 30px;
  }
}
