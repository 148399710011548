.navigation {
  display: flex;
  &__item {
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  &__link {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: color $trans;
    user-select: none;
    position: relative;
    cursor: pointer;

    @supports (-webkit-background-clip: text) {
      background: linear-gradient(to left, $white 0%, $white 33.3%, $red 66.7%, $red 100%);
      background-size: 300% 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: background 500ms ease-in-out;
      background-position: right center;

      &:hover {
        background-position: left center;
      }
    }

    &:before {
      content: '';
      display: block;
      @include sheet;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      transform: translate3d(-10px, -10px, 0);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -3px;
      background-color: transparent;
    }

    &::after {
      background-color: $red;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 400ms ease-in-out;
    }

    &:hover {
      color: $red;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
