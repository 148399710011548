p{
  margin: 0;
}

p + p{
  margin-top: 1em;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 500;
}
