@font-face {
  font-family: 'Ogilvy Serif';
  @include fontSrc('OgilvySerif-Regular');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ogilvy Sans';
  @include fontSrc('OgilvySans-Light');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ogilvy Sans';
  @include fontSrc('OgilvySans-Regular');
  font-weight: 400;
  font-style: normal;
}
