.portfolio-filter {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  width: 100%;
  min-height: 42px;
  border: 1px solid #4f54a5;
  position: relative;
  z-index: 5;
  cursor: pointer;
  background-color: $blue;

  @include mobile {
    max-width: none;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    padding: 3px;
    @include image('chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: calc(100% - 14px);
    padding-right: 42px;
  }



  &__search {
    display: inline-block;
    max-width: 240px;
    width: 100%;
    height: 42px;
    padding-left: 16px ;
    color: $white;
    font-family: $font-base;
    font-size: 18px;
    font-weight: 300;
    &::placeholder {
      color: $grey;
      font-family: $font-base;
      font-size: 18px;
      font-weight: 300;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    max-height: 186px;
    bottom: 0;
    left: 0;
    transform: translate3d(0, calc(100% + 1px), 0);
    overflow: auto;
    border: 1px solid #1b2077;
    border-top: none ;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out;



    ul {
      width: 100%;
      background-color: $blue;
      li {
        &:not(:last-of-type) {
          border-bottom: 1px solid #1b2077;
        }
        &.hide {
          display: none;
        }
        &.notInSearch {
          display: none;
        }
      }
    }

    &-item {
      display: flex;
      padding: 14px 16px;
      color: $white;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      cursor: pointer;
      user-select: none;
      transition: color $trans, background-color $trans;

      &:hover {
        background-color: #1b2077;
        color: $red;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }
  }

  &__label {
    display: inline-flex;
    align-items: center;
    height: 28px;
    padding: 0 32px 0 10px;
    background-color: #1b2077;
    color: $red;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    margin: 3px;
    cursor: pointer;
    @include image('icon_close.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px);

    opacity: 0;
    transform: scale(0.9);
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

    &.animateShow {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.showDropdown {
    .portfolio-filter__dropdown {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}
