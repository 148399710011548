.form {
  display: block;
  width: 100%;

  &__error{
      font-size: 14px;
      line-height: 1;
      color: $white;
      margin-top: 5px;
      display: block;
      opacity: 0;
      &--show{
          opacity: 1;
      }
  }

  &__control{
    display: block;
    width: 100%;
  }
  &__input {
    width: 100%;
    height: 42px;
    background: transparent;
    color: $white;
    font-family: $font-base;
    font-size: 18px;
    font-weight: 300;
    padding: 0 20px;
    border: 1px solid $white;
    transition: border $trans;
    &::placeholder {
      font-family: $font-base;
      font-size: 18px;
      font-weight: 300;
      color: $white;
    }

    //&:-webkit-autofill,
    //&:-webkit-autofill:hover,
    //&:-webkit-autofill:focus {
    //  //border: 1px solid $green;
    //  -webkit-text-fill-color: $black;
    //  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
    //}

    //&:focus {
    //  border-color: $green;
    //  ~ .form__label{
    //    color: $green;
    //    transform: scale(0.84);
    //    top: -0.7rem;
    //  }
    //}

    &--password {
      padding: 0 40px;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: 10px center;
      @include mobile {
        padding-right: 20px;
      }

    }
  }

}
