.text-block {
  width: 100%;
  color: $white;
  font-size: 16px;
  font-weight: 400;
  line-height: calc(22 / 16);

  p {
  }

  &--l {
    font-size: 20px;
    font-size: 15px;
    line-height: calc(26 / 20);
  }

  &--black {
    color: $black;
  }
}
