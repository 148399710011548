.go-back {
  display: inline-flex;
  align-items: center;
  color: $white;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
  transition: color $trans;
  z-index: 10;
  user-select: none;

  @supports (-webkit-background-clip: text) {
    background: linear-gradient(
      to right,
      $white 0%,
      $white 33.3%,
      $red 66.7%,
      $red 100%
    );
    background-size: 300% 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background 500ms ease-in-out;
    background-position: left center;

    &__arrow-bg {
      display: inline-block !important;
    }
    svg {
      display: none !important;
    }

    &:hover {
      background-position: right center;

      .go-back__arrow-bg {
        transition-delay: 300ms;
        background-position: left center;
      }
    }
  }

  &:hover {
    color: $red;
    svg {
      fill: $red;
    }
  }
  svg {
    fill: $white;
    width: 23px;
    height: 18px;
    transform: rotate(180deg);
    margin-right: 10px;
    transition: fill $trans;
  }

  &__arrow-bg {
    display: none;
    width: 23px;
    height: 18px;
    margin-right: 10px;
    transform: rotate(180deg);
    background: linear-gradient(
      to left,
      $white 0%,
      $white 33.3%,
      $red 66.7%,
      $red 100%
    );
    background-size: 300% 100%;
    mask-image: url('../img/arrow_white.svg');
    transition: background 200ms ease-in-out;

    background-position: right center;
  }
}
