.grid-row {
  display: block;
  //max-width: 1420px;
  max-width: 1460px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  @include desktop {
    padding: 0 40px;
  }
}
