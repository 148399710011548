.portfolio {
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  margin: -35px 0;

  &__wrapper {
    width: 33.3333%;
    padding-right: 110px;
    margin: 35px 0;

    &--hidden {
      display: none;
    }

    @include desktop {
      width: 50%;
    }

    @include mobile {
      width: 100%;
      padding-right: 16.776vw;
    }
  }

  // &__link {
  //   &:hover {
  //     .portfolio__title {
  //       color: $white;

  //       background-position: left center;

  //       &:after {
  //         background-position: center;
  //         opacity: 1;
  //         transition-delay: 350ms;
  //       }
  //     }
  //   }
  // }

  &__title {
    width: 100%;
    color: $red;
    font-family: $font-header;
    font-size: 38px;
    font-weight: 400;
    line-height: calc(44 / 38);
    transition: color $trans;
    position: relative;
    @supports (-webkit-background-clip: text) {
      background: linear-gradient(to left, $red 0%, $red 33.3%, $white 66.7%, $white 100%);
      background-size: 300% 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: background 800ms ease-in-out;
      background-position: right center;
    }

    &:after {
      content: '';
      display: inline-flex;
      width: 23px;
      height: 44px;
      margin-left: 12px;
      @include image('arrow_white.svg');
      @include background-contain;
      background-position: -23px;
      overflow: hidden;
      position: absolute;
      opacity: 0;
      transition: background-position 300ms ease-in-out, opacity $trans;
    }

    &:hover{
      color: $white;

      background-position: left center;

      &:after {
        background-position: center;
        opacity: 1;
        transition-delay: 350ms;
      }
    }
  }

  &__details {
    display: inline-block;
    color: $grey;
    font-size: 16px;
    margin-top: 20px;
    color: $white;
    .spacing {
      padding: 0 10px;
    }
  }
}
