.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  user-select: none;
  cursor: pointer;
  color: #ff3d41;
  font-family: $font-base;
  font-size: 0;
  font-weight: 400;
  transition: background-color $trans, color $trans;
  overflow: hidden;
  .span {
    font-size: 18px;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0, 0);
    z-index: 3;
    pointer-events: none;

    &--1 {
      color: $red;
      span {
        position: relative;
      }
    }

    &--2 {
      color: $white;
      span {
        position: relative;
        opacity: 0;

        bottom: -2em;
      }
    }
  }

  &--login {
    width: 128px;
    text-transform: uppercase;
    background-color: $white;
    position: relative;
    color: $red;
    border: 1px solid $white;
    &::after {
      content: '';
      bottom: 0;
      background-color: $red;
      display: block;
      height: 40px;
      left: 0;
      position: relative;
      right: 0;
      top: 0;
      transform: translateY(100%);
      transform-origin: bottom center;
      width: 100%;
      z-index: 1;
    }

    &[data-state='hover'] {
      .span--1 {
        opacity: 0;
        span {
          opacity: 0;
          bottom: -2em;
        }
      }
      .span--2 {
        span {
          opacity: 1;
          bottom: 0;

          transition: opacity 0.3s cubic-bezier(0.65, 0.025, 0.67, 0.36), bottom 0.3s cubic-bezier(0.65, 0.025, 0.67, 0.36);
        }
      }
      &:after {
        transition: transform 0.43s cubic-bezier(0.34, 0.615, 0.4, 0.985);
        transform: translateY(0);
      }
    }

    &[data-state='leave'] {
      .span--1 {
        span {
          opacity: 1;
          bottom: 0;
          transition: opacity 0.3s cubic-bezier(0.65, 0.025, 0.67, 0.36), bottom 0.3s cubic-bezier(0.65, 0.025, 0.67, 0.36);
        }
      }
      .span--2 {
        opacity: 0;
      }
      &:after {
        transform: scaleY(1) translateY(-100%);
        transition: transform 0.2s cubic-bezier(0.65, 0.025, 0.67, 0.36);
      }
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
