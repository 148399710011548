.vimeo-player {
  font-size: 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__play {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 4;
    cursor: pointer;
    user-select: none;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    &:hover {
      svg {
        stroke: $red;
      }
    }
    svg {
      //width: 220px;
      width: 11.458vw;
      //height: 220px;
      height: 11.458vw;
      stroke: $white;
      transition: stroke $trans;
      pointer-events: none;
    }
  }

  &.isPlaying {
    .vimeo-player__play {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

