.footer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 10;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__social {
    .navigation {
      @include mobile {
        flex-direction: column;

        &__item {
          &:not(:last-of-type) {
            margin: 0 0 5px 0;
          }
        }
      }
    }
  }
}
