.heading {
  font-family: $font-header;
  font-size: 40px;
  font-weight: 400;
  line-height: calc(44/40);
  text-transform: uppercase;
  &--red {
    color: $red;
  }

  &--white {
    color: $white;
  }
}
