$imgPath : "/img/";
$trans: 150ms ease-in-out;

$font-base: 'Ogilvy Sans', sans-serif;
$font-header: 'Ogilvy Serif', sans-serif;

$white: #FFFFFF;
$black: #000000;
$red: #ff3d41;
$blue: #23298f;
$grey: #a7a7a7;
