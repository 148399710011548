.bg-image {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  @include tablet {
    flex-direction: column;
  }

  &__image {
    @include sheet;
    @include tablet {
      position: static;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include mobile {
        min-height: 100vh;
      }
    }
  }

  &__text-block {
    max-width: 940px;
    width: 100%;
    background-color: $white;
    padding: 54px 70px;
    z-index: 2;
    align-self: center;
    margin: 0 auto;
    @include tablet {
      align-self: flex-end;
      order: 2;
      max-width: none;
    }
    @include mobile {
      padding: 54px 48px 73px 48px;
    }

    &--type-2 {
      background-color: $red;
      .heading {
        color: $white;
      }
    }

    &--align-bottom {
      align-self: flex-end;
    }

    .heading {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  &--has-text {
    height: 100vh;
    position: absolute;
    @include tablet {
      height: auto;
      position: relative;
    }
  }
}
