.logo {
  display: inline-flex;
  max-width: 410px;
  width: 100%;
  position: relative;

  @include mobile {
    padding: 0 54px;
  }

  img {
    width: 100%;
    height: auto;
  }

  svg {
    width: 100%;
    height: auto;
  }

  .animate-svg path{
    stroke: rgba($white, 0.7);
    fill: $white;
    fill-opacity: 0;
    stroke-width: 0.3;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    will-change: stroke, stroke-dashoffset, fill-opacity;

    animation: DASH4 3s ease-in-out forwards;
  }

//   svg path {
//     stroke: rgba($white, 0.7);
//     fill: $white;
//     fill-opacity: 0;
//     stroke-width: 0.3;
//     stroke-dasharray: 400;
//     stroke-dashoffset: 400;
//     will-change: stroke, stroke-dashoffset, fill-opacity;

//     animation: DASH4 3s ease-in-out forwards;
//   }

  @keyframes DASH4 {
    0% {
      stroke-dashoffset: 400;
    }
    80% {
      stroke-dashoffset: 0;
      fill-opacity: 0;
      stroke: rgba($white, 0.7);
    }
    100% {
      stroke-dashoffset: 0;
      fill-opacity: 1;
      stroke: $white;
    }
  }
}
