.bg-video {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include tablet {
    height: 100vh
  }
  &__video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}
