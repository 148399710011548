*,
*::before,
*::after {
  box-sizing: border-box !important;
}

* {
  @include selection {
    background-color: rgba($red, 0.99);
    color: $white;
  }
}

html {
  font-size: 20px;
  @include mobile {
    //font-size: 3.125vw;
    font-size: 10px;
  }
}

body {
  font-family: $font-base;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}

main {
}
