.preloader {
  position: fixed;
  z-index: 999;
  padding-top: 0;
  display: flex;
  justify-content: center;
  transition: opacity 250ms ease-out, visibility 250ms ease-out;
  .logo {
    margin-bottom: 0;
  }
  .login-form {
    margin-bottom: 0;
  }
}

.isLoaded {
  .preloader {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
