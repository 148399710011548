.notification {
  width: 100%;
  padding: 50px 0;
  &__block {
    display: flex;
  }
  &__icon {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    @include background-contain;
    margin-right: 32px;
    @include mobile {
      width: 14.814vw;
      height: 14.814vw;
    }
  }

  &--blue {
    background-color: $blue;
  }
}
