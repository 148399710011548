.page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 170px;
  //padding: 170px 0;
  background-color: $blue;

  .go-back {
    display: inline-block;
    margin-bottom: 100px;
    @include tablet {
      margin-bottom: 50px;
    }
  }

  .heading {
    margin-bottom: 50px;
  }

  .heading,
  .text-block {
    max-width: 780px;
  }

  &--hidden {
    padding: 0;
    height: 0;
    min-height: 0;
  }
}
