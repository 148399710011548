.section {
  width: 100%;
  position: relative;
  font-size: 0;

  &__login {
    height: 100vh;
    background-color: $red;
    padding-top: 19.9vh;
    display: flex;
    flex-direction: column;
    @include mobile {
      padding-top: 25.925vw;
    }

    .login-form {
      margin-bottom: 50px;
    }

    .notification {
      margin-top: auto;
    }
  }

  &__portfolio {
    background-color: $blue;
    min-height: 100vh;
    //padding: 170px 0;
    padding-bottom: 170px;
    z-index: 10;

    @include mobile {
      padding-bottom: 135px;
    }

    &--fixed-header {
      padding-top: 160px;
      @include tablet {
        padding-top: 230px;
      }
      .header {
        position: fixed;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          to bottom,
          rgba($blue, 1),
          rgba($blue, 0.6)
        );
        z-index: 2;
      }
    }

    &--single-page {
      .header {
        // position: absolute;
        position: sticky;
        top: 0;
        background-image: linear-gradient(
          to bottom,
          rgba($blue, 1),
          rgba($blue, 0.6)
        );
        z-index: 2;
      }
    }
  }

  &__100vh {
    height: 100vh;
    @include tablet {
      height: auto;
    }
  }

  &__200vh {
    height: 200vh;

    @include tablet {
      height: auto;
    }

    .bg-image {
      &__image {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        img {
          display: none;
          width: 100%;
          height: auto;
          @include tablet {
            display: inline;
          }
        }
      }
    }
  }

  &__go-back {
    position: fixed;
    z-index: 3;
    right: 3.125vw;
    top: 3.125vw;
  }
}
