.header {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 100px;
  margin-bottom: 60px;

  //position: absolute;
  //top: 0;
  //left: 0;
  //z-index: 2;

  .grid-row {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

    @include tablet {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;
      align-items: flex-start;
    }

    &:after {
      content: "";
      display: block;
      width: calc(100% - 40px);
      border-bottom: 1px solid rgba($white, 0.2);
      position: absolute;
      bottom: 0;
      @include desktop {
        width: calc(100% - 80px);
      }
    }
  }

  &__logo {
    display: inline-flex;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.0607em;
    color: $red;
    line-height: 1;
    @include tablet {
      margin-bottom: 20px;
    }
    img {
      max-width: 134px;
      margin-right: 8px;
    }
    span {
      align-self: flex-end;
      margin-bottom: 14px;
    }

    svg {
      max-width: 230px;
      width: 100%;
      height: auto;
    }

    svg path {
        stroke: rgba($white, 0.7);
        fill: $red;
        fill-opacity: 0;
        stroke-width: 0.3;
        stroke-dasharray: 550;
        stroke-dashoffset: 550;

        animation: DASH3 3s ease-in-out forwards;
      }

    @keyframes DASH3 {
        0% {
          stroke-dashoffset: 550;
        }
        80% {
          stroke-dashoffset: 0;
          fill-opacity: 0;
          stroke: rgba($white, 0.7);
        }
        100% {
          stroke-dashoffset: 0;
          fill-opacity: 1;
          stroke: $red;
        }
      }

  }

}
